import React from "react";
import config from "../shared/config";
import logoApp from "./../assets/logo-app.png";

export default function header({ user = {} }: { user?: { name?: string } }) {
	return (
		<div
			className="header  navbar navbar-fixed-top"
			style={{
				...(config.style.header_footer ? config.style.header_footer : {}),
				...(config.style.header ? config.style.header : {}),
			}}>
			<a className="navbar-brand" href="/compliance">
				<img src={config?.logo || logoApp} />
			</a>
			<div className="navbar-text">{user?.name}</div>
		</div>
	);
}
