import React, {useEffect, useState} from "react";
import {JaaSMeeting, JitsiMeeting} from "@jitsi/web-sdk";
import {getParameter} from "./shared/urlUtils";
import {decoded} from "./shared/decodeJwt";
import HeaderApp from "./layout/HeaderApp";
import FooterApp from "./layout/FooterApp";
import config from "./shared/config";
import {messageError} from "./data/messageError";
import ErrorManagement from "./ErrorManagement";

function Init() {
    const [data, setData] = useState<any>();
    const [jwt, setJwt] = useState<string>();
    const [dateExpired, setDateExpired] = useState<number>();
    const [dateInit, setDateInit] = useState<number>();
    const [room, setRoom] = useState<string>();
    const [error, setError] = useState<boolean>(false);
    const isModerator = data?.moderator;
    const configOverwrite = config.configOverwrite || {
        startWithAudioMuted: false,
        startWithVideoMuted: false,
        toolbarButtons: ["hangup"],
    }

    useEffect(() => {
        const room = window.location.pathname;
        const jwt = getParameter("jwt");
        if (room && jwt) {
            const data = decoded(jwt);
            if(data.exp && new Date().getTime() > data.exp *1000){
                setDateExpired( data.exp *1000)
            }else if( data.nbf && new Date().getTime() < data.nbf *1000){
                setDateInit(data.nbf *1000 + (15 * 60 * 1000) )
            }

                setData(data);
                setJwt(jwt);
                setRoom(room.substring(1, room.length));
        }
        if (config.title) document.title = config.title;
        const favicon = document?.querySelector("link[rel=icon]");
        if (favicon && config.favicon) (favicon as any).href = config.favicon;
    }, []);
    return (
        <div
            className={`app-container`} /* ${config.variant} */
            style={{height: "100vh"}}>
            <HeaderApp user={data?.context?.user}/>
            <div className="content-page">
                {jwt && data && room && !error && !dateExpired && !dateInit && (
                    <JitsiMeeting
                        // domain={"ae-com-meet-dev.aenduo.com"}
                        domain={config.domain}
                        onApiReady={(externalApi) => {
                            externalApi.addListener("videoConferenceLeft", function () {
                                window.location.href = data.hangup_redirect || config.redirect;
                            });
                            externalApi.addListener("errorOccurred", function (data) {
                                if(data.error.name === 'conference.destroyed'){
                                    window.location.href = data.hangup_redirect || config.redirect;
                                } else {
                                    setError(true)
                                }

                            })
                        }}
                        userInfo={{
                            displayName: data.context.user.name,
                            email: data.context.user.name,
                        }}
                        configOverwrite={configOverwrite}
                        getIFrameRef={(iframe) => {
                            iframe.style.height = "100%";
                        }}
                        jwt={jwt}
                        roomName={room}
                    />
                )}
                {error && <ErrorManagement isModerator={isModerator} message={messageError.genericError()} data={data} />}
                {dateExpired && <ErrorManagement isModerator={isModerator} message={messageError.expired(dateExpired)} data={data} />}
                {dateInit && <ErrorManagement isModerator={isModerator} message={messageError.notActive(dateInit)} data={data} /> }

            </div>
            <FooterApp/>

            {/*{*/}
            {/*  jwt && data && room && <JaaSMeeting*/}
            {/*      appId={'ae-com-meet-dev.aenduo.com'}*/}
            {/*      roomName={room}*/}
            {/*      userInfo={{displayName: data.context.user.name, email: data.context.user.name}}*/}
            {/*      getIFrameRef={(iframe) => { iframe.style.height = '100vh'; }}*/}
            {/*      jwt={jwt}*/}
            {/*      configOverwrite={{prejoinPageEnabled: true}}*/}
            {/*      onApiReady={() => {}}/>*/}
            {/*}*/}
        </div>
    );
}

export default Init;
