import React from "react";
import config from "../shared/config";

export default function header() {
	return (
		<div
			className="footer"
			style={{
				...(config.style.header_footer ? config.style.header_footer : {}),
				...(config.style.footer ? config.style.footer : {}),
			}}>
			<div className="footer-text">
				Aenduo © (2014 - 2021) per {config.brand ? config.brand : "Vivisol"}
			</div>
		</div>
	);
}
