 const dateFormatGGmmmYY = (date: string | number | Date, locales: string = 'it-IT') =>
    new Date(date).toLocaleString(locales, {
        day: '2-digit',
        month: 'short',
        year: '2-digit',
    })
 const hhMM = (date: string | number | Date, locales: string = 'it-IT') =>
    new Date(date).toLocaleString(locales, {
        hour: 'numeric',
        minute: 'numeric',
    })

export const messageError = {
    expired: (date: number) => `L'appuntamento si è concluso e non è più possibile avviarlo.`,
    notActive: (date: number) => `L'appuntamento è previsto per il ${dateFormatGGmmmYY(date)} alle ${hhMM(date)}. Avvia all'appuntamento all'orario di inizio previsto per poter proseguire. `,
    genericError: () => `Non è possibile proseguire ed avviare l'appuntamento.`,
}
