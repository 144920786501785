import jwt_decode from "jwt-decode";


export const decoded  = (token: string): any | false => {
    try{
        return isValid(jwt_decode(token))
    } catch (e) {
        return false
    }
}

export const isValid = (data: any) =>{
    // return data && data.exp > (Date.now()/1000) ?   data : false;
    return data;
}
