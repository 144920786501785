import React from "react";
import config from "./shared/config";


function ErrorManagement({isModerator, message, data}: { isModerator: boolean, message: string, data: any }) {

    return (
        <div className={'w-100 h-100'}>
            <div className={'error-container'}>
                {config?.error?.img && <img src={config?.error?.img} width={'200px'}/>}
                <h3 className={'error-message'}>{message}</h3>

                {isModerator ?
                    <button className={'btn btn-primary'} style={{
                        ...(config.style.header_footer ? config.style.header_footer : {}),
                        ...(config.style.footer ? config.style.footer : {}),
                    }}
                            onClick={() => window.location.href = data.hangup_redirect || config.redirect}>
                        Torna alla piattaforma
                    </button> :
                    <p> Puoi chiudere questa finestra. </p>
                }
            </div>
        </div>
    );
}

export default ErrorManagement;
