import React, {useEffect} from "react";
import Init from "./Init";

function App() {
	const isRedirect = window.location.href.includes('redirect');
	useEffect(() => {
		setTimeout(() => {
			isRedirect && (window.location.href = '/' + window.location.hash.substr(1))
		}, 1)
	}, [])
	return (
		<>
			{!isRedirect && <Init />}
		</>

		)

}

export default App;
